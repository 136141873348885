import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";
// Redux
import { connect, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { apiError, loginUser } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import { RotatingLines } from "react-loader-spinner";

const Login = props => {
  const formData = { userName: "", pass: "" };
  const loginState = useSelector(state => state.Login);
  const [viewPass, setViewPass] = useState(false);
  const [systemInfo, setSystemInfo] = useState({
    latitude: "",
    longitude: "",
    timestamp: "",
    error: 0,
  });
  const successCallback = position => {
    setSystemInfo({
      ...setSystemInfo,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      timestamp: position.timestamp,
    });
  };
  const errorCallback = error => {
    setSystemInfo({ ...systemInfo, error: 1 });
  };
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  }, []);

  const handleSubmit = values => {
    // if (systemInfo.error) {
    //   toast.error("Please trun on location");
    //   return;
    // }
    values = {
      ...values,
      systemInfo: navigator.userAgentData,
      latitude: systemInfo.latitude,
      longitude: systemInfo.longitude,
      appType: "web",
    };
    props.loginUser(values, props.history);
  };
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Recoveryfy.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {props.error && typeof props.error === "string" ? (
                      <Alert color="danger">{props.error}</Alert>
                    ) : null}
                    <Formik
                      enableReinitialize={true}
                      initialValues={formData}
                      validationSchema={Yup.object().shape({
                        userName: Yup.string().required(
                          "Please Enter Your User Name"
                        ),
                        pass: Yup.string().required(
                          "Please Enter Valid Password"
                        ),
                      })}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="form-horizontal">
                          <div className="mb-3">
                            <Label for="userName" className="form-label">
                              User Name
                            </Label>
                            <Field
                              name="userName"
                              type="text"
                              className={
                                "form-control" +
                                (errors.userName && touched.userName
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="userName"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3">
                            <Label for="pass" className="form-label">
                              Password
                            </Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Field
                                name="pass"
                                type={viewPass ? "text" : "password"}
                                autoComplete="true"
                                className={
                                  "form-control" +
                                  (errors.pass && touched.pass
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <button
                                className="btn btn-light "
                                type="button"
                                id="pass-addon"
                                onClick={() => setViewPass(!viewPass)}
                              >
                                {viewPass ? (
                                  <i className="mdi mdi-eye-off-outline"></i>
                                ) : (
                                  <i className="mdi mdi-eye-outline"></i>
                                )}
                              </button>
                            </div>
                            <ErrorMessage
                              name="pass"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            {loginState.loading ? (
                              <button
                                className="btn btn-primary btn-block"
                                type="button"
                              >
                                <RotatingLines
                                  strokeColor="white"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="30"
                                  visible={true}
                                />
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Log In
                              </button>
                            )}
                          </div>
                          {/* <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" /> Forgot your
                                password?
                              </Link>
                            </div> */}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Adciasis. Design by{" "}
                  <a href="https://adciasis.com/" target="new" title="Adciasis">
                    Adciasis
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);
